import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { IntlProvider } from 'react-intl';
import styled from 'styled-components';

import Header from '../components/header';
import Layout from '../components/layout';
import Locales from '../data/locales';


const SuccessQuery = graphql`
  query {
    splash: file(relativePath: { eq: "splash.jpg" }) {
      ...ImageFragment
    }
  }
`

const Hero = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  align-content: center;

  .cover {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    display: block;
    position: absolute;
    width: 400px;
    color: white;
    left: calc(50% - 200px);
    top: calc(50% - 100px);

    a {
      color: white;
    }

    @media only screen and (max-width: 800px) {
      width: 50%;
      left: calc(50% - 25%);
      top: calc(50% - 10%);
    }
  }
`

const SuccessPage = ({ pageContext }) => (
  <StaticQuery query={SuccessQuery}>
    {data => (
      <IntlProvider
        locale={pageContext.langKey}
        messages={Locales[pageContext.langKey]}
      >
        <Layout>
          <Header />
          <Hero>
            <Img
              style={{ width: '100vw' }}
              sizes={data.splash.childImageSharp.sizes}
            />
            <div className="cover" />
            <div className="content">
              <h1>Thanks for contacting us!</h1>
              <Link to="/">Go back to the homepage</Link>
            </div>
          </Hero>
        </Layout>
      </IntlProvider>
    )}
  </StaticQuery>
)

export default SuccessPage
